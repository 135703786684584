import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import AppBar from '../../components/AppBar';
import Toolbar from '../../components/Toolbar';
import {useState} from "react";


const rightLink = {
    fontSize: 16,
    color: 'common.red',
    ml: 3,
    '&:hover': {
        color: 'yellow',
    },
};

const aStyle = {
    width: 200,
    height: 50,
    objectFit: 'scale-down'
};

const imgStyle = {
    width: 200,
    height: 50,
    objectFit: 'scale-down'
};

function DesktopAppAppBar() {
    const [logoUrl, setLogoUrl] = useState("/images/header/logo.png");
    return (
        <div>
            <AppBar position="fixed">
                <Toolbar sx={{justifyContent: 'space-between'}}>
                    <Box sx={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>
                        <Box component="a" href="#start" sx={aStyle}>
                            <Box
                                component="img"
                                src={logoUrl}
                                onMouseEnter={() => {
                                    setLogoUrl("/images/header/logo_revert.png")
                                }}
                                onMouseLeave={() => {
                                    setLogoUrl("/images/header/logo.png")
                                }}
                                alt="logo"
                                sx={imgStyle}/>
                        </Box>
                    </Box>
                    <Box sx={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
                        <Link

                            variant="h6"
                            underline="none"
                            href="#start"
                            sx={rightLink}
                        >
                            {'strona główna'}
                        </Link>
                        <Link
                            variant="h6"
                            underline="none"
                            href="#oferta"
                            sx={{...rightLink, color: 'primary'}}
                        >
                            {'oferta'}
                        </Link>
                        <Link
                            variant="h6"
                            underline="none"
                            href="#portfolio"
                            sx={{...rightLink, color: 'primary'}}
                        >
                            {'portfolio'}
                        </Link>
                        <Link
                            variant="h6"
                            underline="none"
                            href="#cennik"
                            sx={{...rightLink, color: 'primary'}}
                        >
                            {'cennik'}
                        </Link>
                        <Link
                            variant="h6"
                            underline="none"
                            href="#omnie"
                            sx={{...rightLink, color: 'primary'}}
                        >
                            {'o mnie'}
                        </Link>
                        <Link
                            variant="h6"
                            underline="none"
                            href="#kontakt"
                            sx={{...rightLink, color: 'primary'}}
                        >
                            {'kontakt'}
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default DesktopAppAppBar;
