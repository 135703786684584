import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import DesktopAppFooter from "./DesktopAppFooter";
import MobileAppFooter from "./MobileAppFooter";


function AppFooter() {
    const isDesktop = useMediaQuery('(min-width:1300px)');

    return (
        <div id="main-page-footer">
            {isDesktop ? <DesktopAppFooter/> : <MobileAppFooter />}
        </div>
    )
}

export default AppFooter;
