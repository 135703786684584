import * as React from 'react';
import Typography from '../components/Typography';
import MainPageLayout from './MainPageLayout';
import useMediaQuery from "@mui/material/useMediaQuery";


export default function MainPage() {
    const backgroundImage = 'url(/images/header/background.jpg)'
    const isDesktop = useMediaQuery('(min-width:1300px)');
    const text = isDesktop ? "wideo i fotografia z powietrza" : "z powietrza"
    const textSize = isDesktop ? "h2" : "h4"
    const margin = isDesktop ? '0' : '0 0 20vh 0'
    return (
        <MainPageLayout
            sxBackground={{
                backgroundImage: backgroundImage,
                backgroundPosition: 'center',
                boxShadow: '0 6px 20px -4px black',
            }}
        >
            <img
                style={{display: 'none'}}
                src={backgroundImage}
                alt="increase priority"
            />
            {!isDesktop && (
                <Typography variant='h4' sx={{color: 'white', textShadow: '2px 2px 4px #000000', marginTop: '20vh'}}>
                    {
                        'wideo i fotografia'
                    }
                </Typography>
            )}
            <Typography color="inherit" align="center" variant={textSize} marked="center"
                        sx={{textShadow: '2px 2px 4px #000000', margin: {margin}}}>
                {text}
            </Typography>
        </MainPageLayout>
    );
}
