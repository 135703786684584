import * as React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../../components/Typography';
import Box from '@mui/material/Box';
import {Theme} from '@mui/material/styles';
import {SxProps} from '@mui/system';
import Link from '@mui/material/Link';


const item: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '10px 0'
};

const imgItem: SxProps<Theme> = item && {
    height: 35, width: 35, ml: 1, mr: 1, mt: -1.5
};

export default function DesktopAppFooter() {
    return (
        <Box bgcolor="black" display="flex">
            <Container component="section">
                <Grid container sx={{mt: 4, mb: 2}}>
                    <Grid container item sm={4} sx={{alignItems: "left", justifyContent: "left"}}>
                        <Box
                            component="img"
                            src="/images/footer/logo.png"
                            alt="logo"
                            sx={{height: "80px", width: "80px", mt: 0.5}}
                        />
                    </Grid>
                    <Grid container sm={4} sx={{alignItems: "center", justifyContent: "center"}}>
                        <Typography variant="h6" sx={{my: 1, alignSelf: "flex-end", pb: 0.5}} color="white"
                                    fontSize={"8px"}
                                    flexDirection={"column-reverse"}>
                            Copyrgiht &copy; 2022 Micek Drones Wszelkie prawa zastrzeżone.<br/>
                            Icons made by Freepik from www.flaticon.com is licensed by CC 3.0 BY
                        </Typography>
                    </Grid>
                    <Grid container sm={4} sx={{justifyContent: 'flex-end'}}>
                        <Box>
                            <Box sx={item} justifyContent="center" alignItems="center">
                                <Typography variant="h4" color="secondary" fontSize={"24px"} marginBottom={"10px"}>
                                    social media
                                </Typography>
                            </Box>
                            <Box sx={item} justifyContent="center" alignItems="center">
                                <Link href="https://www.facebook.com/micek.drones"
                                      target="_blank"
                                      rel="noopener noreferrer">
                                    <Box
                                        component="img"
                                        src="./images/footer/logo_facebook.png"
                                        alt="logo_facebook"
                                        sx={imgItem}
                                    />
                                </Link>
                                <Link href="https://www.instagram.com/micek.drones/"
                                      target="_blank"
                                      rel="noopener noreferrer">
                                    <Box
                                        component="img"
                                        src="./images/footer/logo_instagram.png"
                                        alt="logo_instagram"
                                        sx={imgItem}
                                    />
                                </Link>
                                <Link href="https://www.tiktok.com/@micek.drones"
                                      target="_blank"
                                      rel="noopener noreferrer">
                                    <Box
                                        component="img"
                                        src='./images/footer/logo_tiktok.png'
                                        alt="logo_tiktok"
                                        sx={imgItem}
                                    />
                                </Link>
                                <Link href="https://www.youtube.com/channel/UCYDMzlrkikLi5lsf062cI_A"
                                      target="_blank"
                                      rel="noopener noreferrer">
                                    <Box
                                        component="img"
                                        src='./images/footer/logo_youtube.png'
                                        alt="logo_youtube"
                                        sx={imgItem}
                                    />
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
