import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import Grid from "@mui/material/Grid";


export default function Pricing() {
    return (
        <Box
            id="cennik"
            component="section"
            sx={{display: 'flex', bgcolor: 'secondary.light', overflow: 'hidden'}}
        >
            <Container component="section" sx={{mt: 10, mb: 10, display: 'flex', position: 'relative'}}>
                <Box
                    component="img"
                    src="/images/common/curvyLines.png"
                    alt="curvy lines"
                    sx={{pointerEvents: 'none', position: 'absolute', top: -180}}
                />
                <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                    <Grid container spacing={9}>
                        <Grid item xs={12} md={12}>
                            <Typography variant="h4" marked="center" align="center" component="h2" sx={{mb: 8}}>
                                cennik
                            </Typography>
                            <Typography variant="h5" align="justify">
                                {
                                    'Każde zlecenie traktowane i wyceniane jest indywidualnie na podstawie wielu czynników, takich jak:'
                                }
                                <ul>
                                    <li>typ zlecenia (krótka sesja zdjęciowa, filmowanie imprezy, relacja z wydarzenia
                                        sportowego),
                                    </li>
                                    <li>rodzaj zlecenia (fotografia, stworzenie filmiku promocyjnego, reklamy),</li>
                                    <li>odległość od miejsca zlecenia,</li>
                                    <li>rodzaj materiału (surowy, z obróbką),</li>
                                    <li>lokalizacja (teren zabudowany, strefa powietrzna),</li>
                                    <li>czas zlecenia (krótki lot, całodniowa relacja),</li>
                                    <li>jakość i format ujęć (ujęcia pionowe/poziome, rozdzielczość),</li>
                                    <li>stopień zaawansowania montażu,</li>
                                    <li>liczba wylotów,</li>
                                    <li>i inne.</li>
                                </ul>
                                {
                                    'Poza dostarczaniem surowych materiałów (zdjęć/nagrań wideo), oferuję również ich kompleksową obróbkę (tworzenie filmików promocyjnych, reklamowych itp.), które również wyceniane jest indywiduwalnie.'
                                }
                                {
                                    'Jesteś zainteresowany współpracą? Skontaktuj się, a przygotuję wycenę dostosowaną do Twojej potrzeby.'
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container></Box>
    );
}
