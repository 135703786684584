import * as React from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";


const item: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '10px 0'
};

const imgItem: SxProps<Theme> = item && {
    height: 35, width: 35, ml: 1, mr: 1, mt: -1.5
};


function MobileAppFooter() {

    return (
        <Box bgcolor="black" display="flex">
            <Container component="section">
                <Grid container sx={{mt: 4, mb: 2}}>
                    <Grid container sm={4} sx={{justifyContent: 'center'}}>
                        <Box>
                            <Box sx={item} justifyContent="center" alignItems="center">
                                <Link href="https://www.facebook.com/micek.drones"
                                      target="_blank"
                                      rel="noopener noreferrer">
                                    <Box
                                        component="img"
                                        src="./images/footer/logo_facebook.png"
                                        alt="logo_facebook"
                                        sx={imgItem}
                                    />
                                </Link>
                                <Link href="https://www.instagram.com/micek.drones/"
                                      target="_blank"
                                      rel="noopener noreferrer">
                                    <Box
                                        component="img"
                                        src="./images/footer/logo_instagram.png"
                                        alt="logo_instagram"
                                        sx={imgItem}
                                    />
                                </Link>
                                <Link href="https://www.tiktok.com/@micek.drones"
                                      target="_blank"
                                      rel="noopener noreferrer">
                                    <Box
                                        component="img"
                                        src='./images/footer/logo_tiktok.png'
                                        alt="logo_tiktok"
                                        sx={imgItem}
                                    />
                                </Link>
                                <Link href="https://www.youtube.com/channel/UCYDMzlrkikLi5lsf062cI_A"
                                      target="_blank"
                                      rel="noopener noreferrer">
                                    <Box
                                        component="img"
                                        src='./images/footer/logo_youtube.png'
                                        alt="logo_youtube"
                                        sx={imgItem}
                                    />
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default MobileAppFooter;
