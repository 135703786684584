import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import DesktopAppAppBar from "./DesktopAppAppBar";
import MobileAppAppBar from "./MobileAppAppBar";


function AppAppBar() {
    const isDesktop = useMediaQuery('(min-width:1300px)');

    return (
        <div id="start">
            {isDesktop ? <DesktopAppAppBar/> : <MobileAppAppBar />}
        </div>
    )
}

export default AppAppBar;
