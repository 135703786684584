import * as React from 'react';
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar';

const AppBarStyle = {
  background: '#000000',
  boxShadow: '0 6px 20px -4px black',
};


function AppBar(props: AppBarProps) {
  return <MuiAppBar elevation={0} position="fixed" {...props} style={AppBarStyle} />;
}

export default AppBar;
