import * as React from 'react';
import Typography from '../components/Typography';
import ContactLayout from "./ContactLayout";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";


export default function Contact() {
    const isDesktop = useMediaQuery('(min-width:1300px)');
    const backgroundShadow = isDesktop ? '0 6px 20px -4px black' : null;
    return (
        <ContactLayout
            sxBackground={{
                backgroundImage: 'url(/images/contact/background.png)',
                backgroundPosition: 'center',
                boxShadow: backgroundShadow,
            }}
        >
            <Typography color="inherit" align="center" variant="h4" marked="center"
                        sx={{color: 'white', textShadow: '2px 2px 4px #000000'}}>
                kontakt
            </Typography>
            <Box sx={{mt: 4, display: 'flex', flexWrap: 'wrap'}}>
                <Grid container spacing={9}>
                    <Grid item xs={12} md={12}>
                        {isDesktop && (
                            <>
                                <Typography variant='h3' sx={{color: 'white', textShadow: '2px 2px 4px #000000', fontSize: '30px'}}>
                                    {
                                        'Jeżeli jesteś zainteresowany współpracą, skontaktuj się,'
                                    }
                                </Typography>
                                <Typography variant='h3' sx={{color: 'white', textShadow: '2px 2px 4px #000000', fontSize: '30px', marginBottom: '40px'}}>
                                    {
                                        'a przygotuję dla Ciebie ofertę skrojoną do Twoich potrzeb.'
                                    }
                                </Typography>
                                <Typography variant='h4' sx={{color: 'white', textShadow: '2px 2px 4px #000000', fontSize: '26px'}}>
                                    {
                                        'PATRYK MICEK'
                                    }
                                </Typography>
                                <Typography variant='h4'
                                            sx={{color: 'white', textShadow: '2px 2px 4px #000000', fontSize: '26px'}}>
                                    {
                                        'patryk@micekdrones.com'
                                    }
                                </Typography>
                                <Typography variant='h4'
                                            sx={{color: 'white', textShadow: '2px 2px 4px #000000', fontSize: '26px'}}>
                                    {
                                        'tel. +48 789 185 428'
                                    }
                                </Typography>
                            </>
                        )}
                        {!isDesktop && (
                            <>
                                <Typography variant='h6' sx={{color: 'white', textShadow: '2px 2px 4px #000000'}}>
                                    {
                                        'PATRYK MICEK'
                                    }
                                </Typography>
                                <Typography variant='h6' sx={{color: 'white', textShadow: '2px 2px 4px #000000'}}>
                                    {
                                        'patryk@micekdrones.com'
                                    }
                                </Typography>
                                <Typography variant='h6' sx={{color: 'white', textShadow: '2px 2px 4px #000000'}}>
                                    {
                                        'tel. +48 789 185 428'
                                    }
                                </Typography>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </ContactLayout>
    );
}
