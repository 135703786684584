import React from 'react';
import './App.css';
import AppAppBar from "./react/modules/views/AppAppBar/AppAppBar";
import MainPage from "./react/modules/views/MainPage";
import Offer from "./react/modules/views/Offer";
import Portfolio from "./react/modules/views/Portfolio";
import AppFooter from "./react/modules/views/AppFooter/AppFooter";
import withRoot from './react/modules/withRoot';
import AboutMe from "./react/modules/views/AboutMe";
import Pricing from "./react/modules/views/Pricing";
import Contact from "./react/modules/views/Contact";

function App() {
  return (
    <div className="App">
        <AppAppBar />
        <MainPage />
        <Offer />
        <Portfolio />
        <Pricing />
        <AboutMe />
        <Contact />
        <AppFooter />
    </div>
  );
}

export default withRoot(App);