import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import Grid from "@mui/material/Grid";


export default function AboutMe() {
    return (
        <Box
            id="omnie"
            component="section"
            sx={{ display: 'flex', overflow: 'hidden' }}
        >
            <Container component="section" sx={{pt: 10, pb: 10}}>
                <Typography variant="h4" marked="center" align="center" component="h2">
                    o mnie
                </Typography>
                <Box sx={{mt: 8, display: 'flex', flexWrap: 'wrap'}}>
                    <Grid container spacing={9}>
                        <Grid item xs={12} md={8}>
                            <Typography variant="h5" sx={{textAlign: "justify"}}>
                                {
                                    'Mam na imię Patryk, jestem pasjonatem fotografii, podróży i sportu. ' +
                                    'Zwiedziłem dziesiątki krajów i właśnie w podróży narodziła się moja pasja - drony. ' +
                                    'Fotografowałem masę pięknych miejsc, ale zawsze czegoś mi brakowało - możliwości stworzenia unikalnych zdjęć wakacyjnych takich, ' +
                                    'jakie można zrobić tylko za pomocą... drona. I tak pierwszy wpadł mi w ręce 3 lata temu, a od tego czasu w moim życiu bardzo wiele się zmieniło. ' +
                                    'Latanie spodobało mi się tak bardzo, że postanowiłem zmienić profesję, połączyć pasję z pracą i zostałem pilotem. ' +
                                    'Przeszedłem wiele kursów fotografii, filmowania, edycji i postprodukcji. ' +
                                    'Posiadam Świadectwo Kwalifikacji UAVO wydane przez Urząd Lotnictwa Cywilnego, ' +
                                    'które jest niezbędne do wykonywania komercyjnych lotów dronem (uprawnienia kat. ogólnej A1, A2, A3 oraz sczególnej NSTS-01, NSTS-05).'
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box
                                component="img"
                                src="/images/aboutme/profile_picture.png"
                                alt="suitcase"
                                sx={{height: 250}}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Container></Box>
    );
}
