import * as React from 'react';
import {useState} from "react";
import AppBar from '../../components/AppBar';
import Toolbar from "../../components/Toolbar";
import Box from "@mui/material/Box";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {Drawer} from "@mui/material";
import Link from "@mui/material/Link";


const aStyle = {
    width: 200,
    height: 50,
    objectFit: 'scale-down'
};

const imgStyle = {
    width: 200,
    height: 50,
    objectFit: 'scale-down'
};

const drawerItem = {
    fontSize: 24,
    textAlign: 'left',
    marginTop: '20px',
    marginLeft: '40px'
};

function MobileAppAppBar() {

    const logoUrl = "/images/header/logo.png"
    const [openDrawer, setOpenDrawer] = useState(false);
    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    return (
        <Box component="div">
            <AppBar position="fixed">
                <Toolbar sx={{justifyContent: 'space-between'}}>
                    <Box sx={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>
                        <Box component="a" href="#start" sx={aStyle}>
                            <Box
                                component="img"
                                src={logoUrl}
                                alt="logo"
                                sx={imgStyle}/>
                        </Box>
                    </Box>
                    <Box sx={{flex: 1, display: 'flex', justifyContent: 'flex-end'}}>
                        <MenuIcon color="primary" fontSize="large" onClick={toggleDrawer} />
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: '100%',
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: '100%',
                        boxSizing: 'border-box',
                        backgroundColor: 'black'
                    },
                }}
                variant="persistent"
                anchor="left"
                open={openDrawer}
            >
                <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '15px'}}>
                    <CloseIcon color="primary" fontSize="large" onClick={toggleDrawer} sx={{marginRight: '15px'}}/>
                </Box>
                <Box sx={{flex: 1, display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', marginTop: '20px'}}>
                    <Link
                        variant="h6"
                        underline="none"
                        href="#start"
                        sx={drawerItem}
                        onClick={toggleDrawer}
                    >
                        {'strona główna'}
                    </Link>
                    <Link
                        variant="h6"
                        underline="none"
                        href="#oferta"
                        sx={drawerItem}
                        onClick={toggleDrawer}
                    >
                        {'oferta'}
                    </Link>
                    <Link
                        variant="h6"
                        underline="none"
                        href="#portfolio"
                        sx={drawerItem}
                        onClick={toggleDrawer}
                    >
                        {'portfolio'}
                    </Link>
                    <Link
                        variant="h6"
                        underline="none"
                        href="#cennik"
                        sx={drawerItem}
                        onClick={toggleDrawer}
                    >
                        {'cennik'}
                    </Link>
                    <Link
                        variant="h6"
                        underline="none"
                        href="#omnie"
                        sx={drawerItem}
                        onClick={toggleDrawer}
                    >
                        {'o mnie'}
                    </Link>
                    <Link
                        variant="h6"
                        underline="none"
                        href="#kontakt"
                        sx={drawerItem}
                        onClick={toggleDrawer}
                    >
                        {'kontakt'}
                    </Link>
                </Box>
            </Drawer>
        </Box>
    );
}

export default MobileAppAppBar;
