import * as React from 'react';
import {Theme} from '@mui/material/styles';
import {SxProps} from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import useMediaQuery from "@mui/material/useMediaQuery";

const mobileTitle: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    px: 5,
    pt: '-50px',
    pb: '40px'
};

const item: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    px: 5,
};

function Offer() {
    const isDesktop = useMediaQuery('(min-width:1300px)');
    return (
        <Box
            id="oferta"
            component="section"
            sx={{display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light'}}
        >
            <Container sx={{pt: 10, pb: 10, display: 'flex', position: 'relative'}}>
                <Box
                    component="img"
                    src="/images/common/curvyLines.png"
                    alt="curvy lines"
                    sx={{pointerEvents: 'none', position: 'absolute', top: -180}}
                />
                <Grid container spacing={5}>
                    <Grid item xs={12} md={4}>
                        {!isDesktop && (
                            <Box sx={mobileTitle}>
                                <Typography color="inherit" align="center" variant="h4" marked="center">
                                    oferta
                                </Typography>
                            </Box>
                        )}
                        <Box sx={item}>
                            <Box
                                component="img"
                                src="/images/offer/home.png"
                                alt="suitcase"
                                sx={{height: 55}}
                            />
                            <Typography variant="h6" sx={{my: 2}}>
                                sprzedaż nieruchomości
                            </Typography>
                            <Typography variant="h5">
                                {
                                    'Krótki film i zdjęcia z powietrza uatrakcyjniają oferty sprzedaży nieruchomości.'
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={item}>
                            <Box
                                component="img"
                                src="/images/offer/ads.png"
                                alt="graph"
                                sx={{height: 55}}
                            />
                            <Typography variant="h6" sx={{my: 2}}>
                                materiały reklamowe
                            </Typography>
                            <Typography variant="h5">
                                {
                                    'Profesjonalny film z drona to doskonała i nowoczesna promocja biznesu, miasta itp.'
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={item}>
                            <Box
                                component="img"
                                src="/images/offer/camera.png"
                                alt="clock"
                                sx={{height: 55}}
                            />
                            <Typography variant="h6" sx={{my: 2}}>
                                plenerowe sesje zdjęciowe
                            </Typography>
                            <Typography variant="h5">
                                {
                                    'Sesja we dwoje, rodzinna, wieczoru panieńskiego z lotu ptaka? Czemu nie!'
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={item}>
                            <Box
                                component="img"
                                src="/images/offer/birthday-cake.png"
                                alt="clock"
                                sx={{height: 55}}
                            />
                            <Typography variant="h6" sx={{my: 2}}>
                                uroczystości
                            </Typography>
                            <Typography variant="h5">
                                {
                                    'Zdjęcia i film z drona będą wspaniałą pamiątką ważnej uroczystości z Twojego życia.'
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={item}>
                            <Box
                                component="img"
                                src="/images/offer/inspections.png"
                                alt="clock"
                                sx={{height: 55}}
                            />
                            <Typography variant="h6" sx={{my: 2}}>
                                inspekcje
                            </Typography>
                            <Typography variant="h5">
                                {
                                    'Skontroluj postęp prac budowlanych za pomocą zdjęć i filmów z drona.'
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={item}>
                            <Box
                                component="img"
                                src="/images/offer/construction.png"
                                alt="clock"
                                sx={{height: 55}}
                            />
                            <Typography variant="h6" sx={{my: 2}}>
                                postępy prac budowlanych
                            </Typography>
                            <Typography variant="h5">
                                {
                                    'Sprawdź stan techniczny obiektów trudno dostępnych i niebezpiecznych. (np. dachu)'
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={item}>
                            <Box
                                component="img"
                                src="/images/offer/sport-car.png"
                                alt="clock"
                                sx={{height: 55}}
                            />
                            <Typography variant="h6" sx={{my: 2}}>
                                sesje samochodów
                            </Typography>
                            <Typography variant="h5">
                                {
                                    'Spraw sobie prezent w postaci filmu lub sesji zdjęciowej swojego ukochanego pojazdu.\n'
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={item}>
                            <Box
                                component="img"
                                src="/images/offer/mass_parties.png"
                                alt="clock"
                                sx={{height: 55}}
                            />
                            <Typography variant="h6" sx={{my: 2}}>
                                imprezy masowe
                            </Typography>
                            <Typography variant="h5">
                                {
                                    'Uwiecznij wydarzenie sportowe, event firmowy, koncert itp. za pomocą drona.'
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={item}>
                            <Box
                                component="img"
                                src="/images/offer/inspiration.png"
                                alt="clock"
                                sx={{height: 55}}
                            />
                            <Typography variant="h6" sx={{my: 2}}>
                                twoja sugestia
                            </Typography>
                            <Typography variant="h5">
                                {
                                    'Masz pomysł na inne wykorzystanie ujęć z drona? Skontaktuj się!'
                                }
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Offer;
